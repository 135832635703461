@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

h1{
    font-family: 'Lora', serif;
}

.group:after {
    content: "";
    display: table;
    clear: both;
}

.wrapper{
    width: 100%;
    max-width: 80rem;
    margin: auto;
    padding: 0 1rem;
}

.main_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
}

.logo{
    -webkit-transition: color 350ms ease;
    transition: color 350ms ease;
    font-size: 2rem;
    text-transform: uppercase;
    color: #192b59;
    position: relative;
}
.logo:hover{
    opacity: 0.7;
}

.main_menu a{
    -webkit-transition: color 350ms ease;
    transition: color 350ms ease;
    color: #242424;
    line-height: 1em;
    text-decoration: none;
    margin-left: 3rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
}
.main_menu a:hover{
    opacity: 0.7;
}
.main_menu a.active{
    color:red;
}

.page_title{
    background-color: #192b59;
    color: #FFFFFF;
    padding: 1.5rem 0;
}
.page_title h1{
    margin: 0;
    padding: 0;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.page_content{
    margin: 5rem auto;
    line-height: 1.8;
    letter-spacing: 0.02rem;
    text-align: justify;
    max-width: 80%;
    font-size: 1.1rem;
}
.page_content h2{
    margin: 0;
    padding: 0;
}

.box_photo{
    display: flex;
    margin-bottom:1.5rem;
}
.box_photo img{
    width: 100%;
}
.box_photo .img_dx{
    width: 50%;
    margin: 0.5rem;
}
.box_photo .img_sx{
    width: 50%;
    margin: 0.5rem;
}

.main_footer{
    padding:2rem 0;
    background-color: #FFF;
    color: #192b59;
    border-top: 1px #192b59 solid;
}
.main_footer .wrapper{
    display: flex;
    justify-content: space-between;
}
.main_footer .footer_col{
    width: 33%;
    line-height: 1.6;
    font-size: 0.9rem;
    letter-spacing: 0.02rem;
}
.main_footer .footer_col2{
    text-align: center;
}

.main_footer .footer_col3{
    text-align: right;
}

.menu_prodotti{
    background-color: #D0D0D0;
}
.menu_prodotti .wrapper{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu_prodotti a{
    margin: 1rem;
    letter-spacing: 0.01rem;
}
.menu_prodotti a.active{
    color:red;
}

.legal{
    margin:3rem 0;
    text-align: center;
    font-size: 0.75rem;
}

.servizi-img{
    display: block;
}

.image-box{
    overflow: hidden;
    width: 32%;
    height: 0;
    margin-bottom: 1.5rem;
    background-color: #F0F0F0;
    position: relative;
    padding-bottom: 32%;
}

.each-slide img{
    display: block;
}

@media screen and (max-width: 992px) {
    .main_menu{
        display: none;
    }
}
